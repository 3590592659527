import React, { ReactNode } from "react";
import KnowledgeCentreMenu from "../components/knowledgeCentre/KnowledgeCentreMenu";
import Layout from "../components/Layout";

import { styled } from "../stitches/stitches.config";

const KnowledgeCenterPage = ({
  linkPath,
  seo,
  children,
}: {
  linkPath: string;
  seo: {
    title?: string;
    description?: string;
  };
  children: ReactNode;
}) => {
  return (
    <Layout seo={seo} backgroundColorUnderneath={"white"}>
      <Container>
        <KnowledgeCentreMenu activeLinkPath={linkPath} />
        <Content>{children}</Content>
      </Container>
    </Layout>
  );
};

export default KnowledgeCenterPage;

const Container = styled("div", {
  margin: "auto",
  maxWidth: 1140,
  "@lg": {
    width: "95%",
    display: "flex",
    paddingBottom: 200,
    marginTop: 50,
  },
});

const Content = styled("div", {
  width: "100%",
});
