import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Cards from "../components/knowledgeCentre/Cards";
import FAQ from "../components/knowledgeCentre/FAQ";
import Layout from "../components/Layout";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "FAQ",
  description:
    "See answers to the most common questions we get asked here at Plastometrex. Technical requirements for PIP testing, pricing modles, sample preparation and more",
};

const title = `FAQ`;
const byline = `See the answers to our most commonly asked questions below.`;

function FAQPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/faq/" } }
        sort: { fields: [frontmatter___priority], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              question
              answer
            }
          }
        }
      }
    }
  `);

  return (
    <KnowledgeCentrePage seo={seo} linkPath="/faq">
      <FAQ title={title} byline={byline} data={data} />
    </KnowledgeCentrePage>
  );
}

export default FAQPage;
