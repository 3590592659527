import React, { useState } from "react";
import {
  heading100,
  heading300,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";

import { styled } from "../../stitches/stitches.config";
import PlusIcon from "./assets/plus.inline.svg";
import MinusIcon from "./assets/minus.inline.svg";

const QuestionAnswer = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <QuestionContainer>
      <TopContent onClick={() => setIsActive(!isActive)}>
        <QuestionText isActive={isActive}>{question}</QuestionText>
        <IconWrapper>{isActive ? <MinusIcon /> : <PlusIcon />}</IconWrapper>
      </TopContent>
      <AnswerText isVisible={isActive}>{answer}</AnswerText>
    </QuestionContainer>
  );
};
interface FAQProps {
  title: string;
  byline: string;
  data: { [key: string]: any };
}

const FAQ = ({ title, byline, data }: FAQProps) => {
  return (
    <Container>
      <HeadingContainer>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </HeadingContainer>
      <FAQContainer>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const edgeData = edge.node.frontmatter;
          return (
            <QuestionAnswer
              key={edgeData.question}
              question={edgeData.question}
              answer={edgeData.answer}
            />
          );
        })}
      </FAQContainer>
    </Container>
  );
};

export default FAQ;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  paddingBottom: 60,
});

const HeadingContainer = styled("div", {
  marginTop: 20,
  "@md": {
    marginTop: 0,
  },
});

const Title = styled("h2", {
  ...heading300,
  margin: 0,
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
});

const FAQContainer = styled("div", {
  margin: "auto",
  marginTop: 26,
  width: "95%",
  display: "flex",
  flexDirection: "column",
  "@md": {
    width: "100%",
  },
});

const QuestionContainer = styled("div", {
  width: "100%",
  backgroundColor: "$white",
  marginBottom: 16,
  padding: 32,
  borderRadius: 8,
});
const QuestionText = styled("div", {
  ...heading100,
  color: "$blue100",
  fontWeight: 500,
  variants: {
    isActive: {
      true: {
        color: "$blue100",
      },
      false: {
        color: "$black100",
      },
    },
  },
});
const AnswerText = styled("div", {
  ...paragraphTextMedium,
  lineHeight: "32px",
  marginTop: 10,
  variants: {
    isVisible: {
      true: {},
      false: {
        display: "none",
      },
    },
  },
});

const TopContent = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  alignItems: "center",
});
const IconWrapper = styled("div", {
  marginLeft: 10,
});
